$(function() {

	$('.header__slick').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		dots: true,
		autoplay: true,
	  autoplaySpeed: 5000,
		arrows: false
	})

	$('.slick-news').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: $('.slick-news_pagination').find('.prev'),
		nextArrow: $('.slick-news_pagination').find('.next'),
		dots: true,
		appendDots: $('.slick-news_pagination').find('.dots'),
		responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
		}, {
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}
		}]
	})

	$('.slick-manager').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: $('.slick-manager_pagination').find('.prev'),
		nextArrow: $('.slick-manager_pagination').find('.next'),
		dots: true,
		appendDots: $('.slick-manager_pagination').find('.dots'),
		responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
		}, {
				breakpoint: 575,
				settings: {
					slidesToShow: 1,
				}
		}]
	})

	$('.slick-services').slick({
		slidesToShow: 4,
		slidesToScroll: 1,
		prevArrow: $('.slick-services_pagination').find('.prev'),
		nextArrow: $('.slick-services_pagination').find('.next'),
		dots: true,
		appendDots: $('.slick-services_pagination').find('.dots'),
		responsive: [{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
		}, {
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				}
		}]
	})

	$('.slick-command').slick({
		slidesToShow: 1,
		fade: true,
		slidesToScroll: 1,
		prevArrow: $('.slick-command_pagination').find('.prev'),
		nextArrow: $('.slick-command_pagination').find('.next'),
		dots: true,
		appendDots: $('.slick-command_pagination').find('.dots')
	})

	$('.slick-review').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.slick-review_pagination').find('.prev'),
		nextArrow: $('.slick-review_pagination').find('.next'),
		dots: true,
		appendDots: $('.slick-review_pagination').find('.dots')
	})

	$('.menu-mobile').on('click', function() {
		$('nav').toggle('slide', 300);
		return false
	})

	$('nav .close').on('click', function() {
		$('nav').hide('slide', 300);
		return false
	})

	$('main, footer').on('click', function() {
		$('nav').hide('slide', 300);
	})

	$('.nav-menu').on('click', 'strong', function() {
		$(this).toggleClass('active')
	})

	$('.city select').selectize()

	$('input, textarea').on('blur', function() {
		if($(this).val().length > 0) {
			$(this).next('label').addClass('active')
		} else {
			$(this).next('label').removeClass('active')
		}
	})

	$('.malihu').mCustomScrollbar();

	$('.ov').hover(function() {
		$('header .overlay').stop().show('fade', 500)
	}, function() {
		$('header .overlay').hide('fade', 800)
	})


	$('.opt-item').hover(function() {
		$('.opt-item').css({
			'flex': '0 1 25%'
		}).find('p').css({
			'opacity': 0
		})
		$(this).css({
			'flex': '0 0 50%'
		}).find('p').css({
			'opacity': 1
		})
	})

	$('[type="file"]').on('change', function(e) {
		$(this).next('p').text(e.currentTarget.files[0].name)
	})

	var _n = 0;

	$('.sp').on('click', function() {
		var _l = $('.partners-item').length;

		$('.partners-count').text(_l + '/' + _l)

		$('.partners-item').each(function() {
			$(this).delay(_n).queue(function(n) {
				$(this).css({'display': 'flex'})
				n();
			})
			_n = _n + 10
		})
		return false
	})

})